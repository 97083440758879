import Deposit from '@/components/dialogs/deposit'
import Loading from "@/components/widgets/Loading.vue"
export default {
  components: {
    Deposit,
    Loading
  },
  data() {
    return {
      loading: false,
      item: null
    }
  },
  methods: {
    onInfoClose() {
      this.$router.push("/deposit")
    },
    async onInitData() {
      try {
        this.loading = true
        const param = {
          _id: this.$route.params?.id
        }

        const company = await this.$store.dispatch('Setting/get', { root: true })
        const result = await this.$store.dispatch('Deposit/get', param, { root: true })
        setTimeout(() => {
          this.loading= false
          if (result.status === 200) {
            this.item = result.data
            this.item['company'] = company.data
          }
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  },
  mounted() {
    this.onInitData()
  }
}